<template>
  <div class="benefits-container">
    <div class="benefits-title">
      Ich bin ein/e
    </div>
    <v-tabs v-model="tab" centered>
      <v-tabs-slider color="transparent"></v-tabs-slider>

      <v-tab key="1">
        Hotelier
      </v-tab>
      <v-tab key="2">
        Tourismusdestination
      </v-tab>
      <v-tab key="3">
        Verleiher
      </v-tab>
      <v-tab key="4">
        Unternehmen (HR rent)
      </v-tab>
      <v-tab key="5">
        Kommune
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="1">
        <v-card flat>
          <v-card-text>
            <div class="benefits-list">
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Unkomplizierte Verwaltung der E-Bikes & E-Scooter für Rezeption & Hausmeister
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Neue Hotelgäste durch die Sichtbarkeit der E-Bike Flotte gewinnen
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Analyse von Standortdaten, Kilometer- und Batteriestand, Service Alarm uvm.
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Integration & Upgrade der bereits bestehenden E-Bike & E-Scooter Flotte
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Sperr- & Entsperr-Funktion mit 100 % Verlust- u. Diebstahlschutz der E-Bikes & E-Scooter
              </div>
              <!--<div class="benefits-list-element">
                <v-btn color="primary" outlined>Demo anfordern</v-btn>
              </div>-->
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="2">
        <v-card flat>
          <v-card-text>
            <div class="benefits-list">
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Integration von neuen und bestehenden E-Bikes & E-Scootern von jedem Kooperationsinteressierten Händler,
                Hotelier & Vermieter der Destination
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Individuelle SUPER White Label SaaS Renting Lösung, die aus Sicht des Gastes für alle SUPER Renting
                Partner weltweit genutzt werden kann
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Analyse von Standortdaten, Kilometer- und Batteriestand, Service Alarm uvm.
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Unkomplizierte Zentrale- & Einzel-Verwaltung der Fahrzeuge möglich
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Länderübergreifende Mobility-Lösung unkompliziert umsetzbar
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Lösung der "Letzten Meile" durch Integration aller Fahrzeuge
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Kundendaten zur direkten Kundenkommunikation in der gesamten Destination
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Sperr- & Entsperr-Funktion mit 100% Verlust- und Diebstahlschutz der E-Bikes & E-Scooter
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="3">
        <v-card flat>
          <v-card-text>
            <div class="benefits-list">
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Integration von neuen und bestehenden E-Bikes & E-Scootern durch Anbringung des Super Trackers am oder im Motor
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Individuelle SUPER White Label SaaS Renting Lösung, die aus Sicht des Gastes für alle SUPER Renting
                Stationen weltweit genutzt werden kann
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Analyse von Standortdaten, Kilometer- und Batteriestand, Service Alarm uvm.
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Unkomplizierte Verwaltung aller Fahrzeuge & Kundendaten
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Partnerübergreifende Mobility-Lösung unkompliziert umsetzbar
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Standortbasiertes Geofencing individuell möglich
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Kundendaten zur direkten Kundenkommunikation für Service & Verkauf
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Sperr- & Entsperr-Funktion mit 100% Verlust- und Diebstahlschutz der E-Bikes & E-Scooter
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="4">
        <v-card flat>
          <v-card-text>
            <div class="benefits-list">
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Integration von neuen und bestehenden E-Bikes & E-Scootern & Fahrzeugen
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Individuelle SUPER White Label SaaS Renting Lösung, die jeder Mitarbeiter aber auch Gast der
                Firma nutzen kann
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Analyse von Standortdaten, Kilometer- und Batteriestand, Service Alarm uvm.
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Unkomplizierte zentrale Verwaltung & EinzelVerwaltung der Fahrzeuge möglich
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Mittels API individuelle Abrechnung der Mitarbeiternutzung möglich
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Standortbasiertes Geofencing für verschiedene Unternehmensstandorte möglich
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Sharing mit externen Kunden & Partnern möglich um Kosten zu reduzieren
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Sperr- & Entsperr-Funktion mit 100% Verlust- und Diebstahlschutz der E-Bikes & E-Scooter
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="5">
        <v-card flat>
          <v-card-text>
            <div class="benefits-list">
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Integration von neuen und bestehenden Fahrzeugen mit kooperationsinteressierten Partnern, Händler,
                Hotelier & Vermieter der Kommune
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Individuelle SUPER White Label SaaS Renting Lösung, die aus Sicht des Einheimischen & des Gastes der Kommune
                für alle SUPER Renting Partner weltweit genutzt werden kann
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Analyse von Standortdaten, Kilometer- und Batteriestand, Service Alarm uvm.
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Unkomplizierte zentrale Verwaltung der Fahrzeuge möglich
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Stadt- und kommunenübergreifende Mobility-Lösung unkompliziert umsetzbar
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Lösung der "Letzten Meile" durch Integration aller Fahrzeuge
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Kundendaten zur direkten Kundenkommunikation in der gesamten Destination
              </div>
              <div class="benefits-list-element">
                <v-icon color="black">{{icons.check}}</v-icon>
                Sperr- & Entsperr-Funktion mit 100% Verlust- und Diebstahlschutz der E-Bikes & E-Scooter
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import {mdiCheck} from "@mdi/js";

export default {
  name: "Benefits",
  data: () => ({
    tab: null,
    icons: {
      check: mdiCheck,
    },
  }),
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/variables.scss";
  @import "../../assets/scss/layout.scss";
  .benefits-container {
    text-align: center;
    margin-top: 50px;
    .benefits-title {
      color: black;
      font-size: 35px;
      line-height: 38px;
      font-weight: normal;
      margin-left: -550px;
      margin-bottom: 15px;
      @include MQ(XS) {
        margin-left: 25px;
        text-align: left;
      }
    }
    .v-tabs {
      .v-tab {
        text-transform: none;
        border: 2px solid $primary;
        border-radius: 5px;
        color: $primary !important;
        font-weight: normal;
        &.v-tab--active {
          background-color: $primary;
          color: white !important;
        }
      }
      ::v-deep .v-slide-group__prev {
        width: 0;
        min-width: 0;
      }
    }
    .benefits-list {
      display: inline-block;
      text-align: left;
      color: black;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      max-width: 780px;
      .benefits-list-element {
        margin-bottom: 15px;
        margin-left: 40px;
        @include MQ(S) {
          margin-left: 13px;
        }
        @include MQ(XS) {
          margin-left: 13px;
        }
        .v-icon {
          margin-right: 10px;
        }
        .v-btn {
          text-transform: none;
          border-width: 2px;
          border-radius: 5px;
        }
      }
    }
  }
</style>
